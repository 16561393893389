import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Tool from '../components/Tool';
import BackgroundAnimation from '../components/BackgroundAnimation';
import UserProfileMenu from '../components/UserProfileMenu';
import { motion } from 'framer-motion';

const API_URL = process.env.REACT_APP_API_URL;

function Title() {
  return (
    <motion.header
      className="text-center my-8"
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1 className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
        Discover Your Recs
      </h1>
    </motion.header>
  );
}

function getEventType(event_type) {
  if (event_type === 'tool_addition') {
    return 'started recommending';
  } else if (event_type === 'tool_removal') {
    return 'stopped recommending';
  } else if (event_type === 'follow_user') {
    return 'started following';
  } else if (event_type === 'unfollow_user') {
    return 'stopped following';
  }
  return event_type;
}

function FeedItem({ activity }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  return (
    <motion.div
      className="bg-white shadow-md rounded-lg p-4 mb-4 hover:shadow-lg transition-all duration-300 transform hover:scale-105"
      whileHover={{ scale: 1.05 }}
      onHoverStart={handleHover}
      onHoverEnd={() => setIsHovered(false)}
    >
      {/* User Info */}
      <div className="flex items-center mb-2">
        <motion.div
          className="relative mr-3 cursor-pointer group"
          whileHover={{ rotate: 360 }}
          transition={{ duration: 0.5 }}
          onClick={() => window.location.href = activity.user.url}
        >
          <img
            src={activity.user.picture}
            alt={activity.user.username}
            className="w-10 h-10 rounded-full transition-all duration-300 group-hover:scale-105"
          />
        </motion.div>
        <span className="font-semibold">{activity.user.username}</span>
        <span className="text-gray-600 ml-1"> {getEventType(activity.event_type)}</span>

        {activity.event_type === 'follow_user' && (
          <div className="flex items-center ml-1 mr-2">
            <motion.div
              className="relative cursor-pointer group"
              whileHover={{ rotate: 360 }}
              transition={{ duration: 0.5 }}
              onClick={() => window.location.href = activity.target_user.url}
            >
              <img
                src={activity.target_user.picture}
                alt={activity.target_user.username}
                className="w-8 h-8 rounded-full transition-all duration-300 group-hover:scale-105"
              />
            </motion.div>
            <span className="font-semibold mr-2 ml-1">{activity.target_user.username}</span>
          </div>
        )}
      </div>

      {activity.event_type.startsWith('tool_') &&
        <motion.div
          className="mt-2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Tool
            tool={activity.tool}
            userId={activity.user.id}
            isAuthenticated={false}
            onRemove={() => {}}
          />
        </motion.div>
      }

      {isHovered && (
        <motion.div
          className="absolute top-0 left-0 w-full h-full pointer-events-none"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="w-full h-full bg-gradient-to-r from-purple-400 to-pink-600 opacity-5 rounded-lg" />
        </motion.div>
      )}
    </motion.div>
  );
}

function EmptyFeedMessage() {
  return (
    <motion.div
      className="text-center py-8 px-4 bg-white rounded-lg shadow-md"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="mb-6">
        <motion.img
          src='https://i.ibb.co/R2cQ1sL/logo-anyrecs.png'
          alt="logo"
          className="w-64 h-auto mx-auto mb-6"
          whileHover={{
            scale: 1.1,
            x: [-2, 2, -2],
            y: [-1, 1, -1],
            transition: {
              x: { repeat: Infinity, duration: 0.5 },
              y: { repeat: Infinity, duration: 0.5 },
              scale: { duration: 0.3 },
              rotate: { duration: 0.3 }
            }
          }}
        />
      </div>
      <h2
        className="text-3xl font-bold mb-4 text-gradient"
      >
        Your Recommendations Await!
      </h2>
      <p className="text-xl mb-6">
        Oops! Your recommendations are looking a bit empty.
        Start following people to see their recommendations!
      </p>
      {/* TODO: Add this back in once we have a '/explore' page */}
      {/* <Link to="/explore" className="inline-block mt-6 px-6 py-3 bg-gradient-to-r from-blue-500 via-purple-500 to-green-500 text-white font-bold rounded-full hover:from-blue-600 hover:via-purple-600 hover:to-green-600 transition-all duration-300 transform hover:scale-105">
        Start Building Your Network!
      </Link> */}
    </motion.div>
  );
}

function HomePage() {
  const navigate = useNavigate();
  const [feedItems, setFeedItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFeed = async () => {
      try {
        const response = await axios.get(`${API_URL}/user/me/feed`, { withCredentials: true });
        setFeedItems(response.data);
        setIsLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // If not authenticated, redirect to login
          navigate('/login');
        } else {
          setError('Failed to fetch feed. Please try again later.');
          setIsLoading(false);
        }
      }
    };

    fetchFeed();
  }, [navigate]);

  if (isLoading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-600">{error}</div>;
  }

  return (
    <div className="max-w-2xl mx-auto px-4 min-h-screen flex flex-col">
      <BackgroundAnimation
        numberOfShapes={feedItems.length > 0 ? 20 : 40}
        maxSize={feedItems.length > 0 ? 80 : 200}
        images={feedItems.length > 0 ? feedItems.filter(item => item.tool).map(item => item.tool.logo) : ['https://i.ibb.co/HV8Hdfz/logo2.png']}
      />
      <UserProfileMenu />
      <motion.div
        className={`mt-8 ${feedItems.length === 0 ? 'flex-grow flex items-center justify-center' : ''}`}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {feedItems.length === 0 ? (
          <EmptyFeedMessage />
        ) : (
          <>
            <Title />
            {feedItems.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <FeedItem activity={item} />
              </motion.div>
            ))}
          </>
        )}
      </motion.div>
    </div>
  );
}

export default HomePage;