import React from 'react';
import './BackgroundAnimation.css';

function BackgroundAnimation({ numberOfShapes = 20, images = [], minSize = 15, maxSize = 150 }) {
  // Function to generate random values within a range
  const getRandom = (min, max) => Math.random() * (max - min) + min;

  return (
    <div className="area">
      <ul className="circles">
        {[...Array(numberOfShapes)].map((_, index) => {

          const size = getRandom(minSize, maxSize);
          const left = getRandom(0, 100); // Percentage position from left
          const top = getRandom(10, 50); // distance from top
          const animationDelay = 0; // No delay
          const animationDuration = getRandom(7, 30) + (size / 150) * 20; // Duration between 7s and 60s, scaled with size
          const backgroundImage = images[Math.floor(Math.random() * images.length)];

          const style = {
            top: `-${top}vh`,
            width: `${size}px`,
            height: `${size}px`,
            left: `${left}%`,
            animationDelay: `${animationDelay}s`,
            animationDuration: `${animationDuration}s`,
            backgroundImage: `url(${backgroundImage})`,
          };

          return <li key={index} style={style}></li>;
        })}
      </ul>
    </div>
  );
}

// Wrap the component with React.memo to prevent re-renders unless props change
export default React.memo(BackgroundAnimation);
