import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const FeedbackOverlay = ({ onClose }) => {
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      const fadeOutTimer = setTimeout(() => {
        setIsSuccess('fading');
      }, 1000); // Start fading after 1.5 seconds

      const closeTimer = setTimeout(() => {
        onClose();
      }, 2500); // Close the overlay after 2.5 seconds total

      return () => {
        clearTimeout(fadeOutTimer);
        clearTimeout(closeTimer);
      };
    }
  }, [isSuccess, onClose]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      await axios.post(`${API_URL}/feedback/`,
        {
          content: feedback,
          rating
        }, {
        withCredentials: true
      });

      setIsSuccess(true);
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setErrorMessage('Failed to submit feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStars = () => {
    return [1, 2, 3, 4, 5].map((star) => (
      <button
        key={star}
        type="button"
        onClick={() => setRating(star)}
        className={`text-2xl ${star <= rating ? 'text-yellow-400' : 'text-gray-300'}`}
      >
        ★
      </button>
    ));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md z-50 transition-opacity duration-1000 ${isSuccess === 'fading' ? 'opacity-0' : 'opacity-100'}`}>
        {isSuccess ? (
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-4 text-indigo-600">Thank You!</h2>
            <p className="text-gray-700">Your feedback has been submitted successfully.</p>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold mb-4">Provide Feedback</h2>
            <form onSubmit={handleSubmit}>
              <textarea
                className="w-full h-32 p-2 border rounded mb-4"
                placeholder="Enter your feedback here..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                required
              />
              <div className="mb-4 flex justify-center">{renderStars()}</div>
              {errorMessage && (
                <div className="mb-4 text-red-500 text-center">{errorMessage}</div>
              )}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={onClose}
                  className="mr-2 px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50"
                  disabled={isSubmitting || rating === 0}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackOverlay;