import React, { useState, useEffect } from 'react';
import { User, MessageSquare, LogOut, ChevronDown, List } from 'lucide-react';
import axios from 'axios';
import FeedbackOverlay from './FeedbackOverlay';

const API_URL = process.env.REACT_APP_API_URL;

const UserProfileMenu = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${API_URL}/user/me`, { withCredentials: true });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      await axios.post(`${API_URL}/auth/logout`, {}, { withCredentials: true });
      localStorage.removeItem('isAuthenticated');
      window.location.href = '/login';
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (isLoading) {
    return <div className="fixed top-4 right-4">Loading...</div>;
  }

  if (!userData) {
    return null;
  }

  return (
    <div className="fixed top-4 right-4 z-50">
      <div
        className="flex flex-col items-center cursor-pointer"
        onClick={toggleMenu}
        aria-label="User menu"
      >
        <button
          className="h-10 w-10 rounded-full overflow-hidden focus:outline-none hover:ring-2 hover:ring-gray-300"
        >
          <img
            src={userData.picture || "https://randomuser.me/api/portraits/lego/0.jpg"}
            alt={userData.username}
            className="h-full w-full object-cover"
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop
              e.target.src = "https://randomuser.me/api/portraits/lego/0.jpg"; // Set to default Lego image on error
            }}
          />
        </button>
        <ChevronDown
          className={`h-5 w-5 mt-1 text-gray-700 transform transition-transform ${
            isMenuOpen ? 'rotate-180' : ''
          }`}
        />
      </div>

      {isMenuOpen && (
        <div className="mt-2 flex flex-col items-end space-y-2">
          <button
            className="h-10 w-10 bg-white rounded-full focus:outline-none hover:ring-2 hover:ring-gray-300 flex items-center justify-center"
            onClick={() => window.location.href = userData.url}
            aria-label="User profile"
          >
            <User className="h-6 w-6 text-gray-700" />
          </button>
          <button
            className="h-10 w-10 bg-white rounded-full focus:outline-none hover:ring-2 hover:ring-gray-300 flex items-center justify-center"
            onClick={() => window.location.href = '/'}
            aria-label="Feed page"
          >
            <List className="h-6 w-6 text-gray-700" />
          </button>
          <button
            className="h-10 w-10 bg-white rounded-full focus:outline-none hover:ring-2 hover:ring-gray-300 flex items-center justify-center"
            onClick={() => setIsFeedbackOpen(true)}
            aria-label="Provide feedback"
          >
            <MessageSquare className="h-6 w-6 text-gray-700" />
          </button>
          <button
            className="h-10 w-10 bg-white rounded-full focus:outline-none hover:ring-2 hover:ring-gray-300 flex items-center justify-center"
            onClick={handleLogout}
            aria-label="Logout"
          >
            <LogOut className="h-6 w-6 text-gray-700" />
          </button>
        </div>
      )}

      {isFeedbackOpen && (
        <FeedbackOverlay onClose={() => setIsFeedbackOpen(false)} />
      )}
    </div>
  );
};

export default UserProfileMenu;